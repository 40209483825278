import { Container } from './styles';
import warning from '../../../assets/icons/warning.svg';
import close from '../../../assets/icons/close.svg';
import { useEffect, useState } from 'react';
import { useGlobalStore } from '../../../../infra/state/GlobalStore';
import { useAlert } from '../../../../infra/config/AlertContext/useAlert';
import { DeleteCampaignUseCase } from '../../../../application/useCases/campaign/OrgDeleteCampaign';
import { SmallRedButton, SmallTransparentBlueButton } from '../../common/Button';
import { GetTokenUseCase } from '../../../../application/useCases/web3/getTokenUseCase';
import { TextArea } from '../../common/TextArea';
import { Loading } from '../../common/Loading';

interface ModalIconProps {
  open: boolean;
  onClose: () => void;
  campaignId: number;
  institutionId: number;
  posts: any[];
  setPosts: React.Dispatch<React.SetStateAction<any[]>>;
}

export const ModalContractData = ({
  onClose,
  campaignId,
  setPosts,
  posts,
  institutionId,
}: ModalIconProps) => {
  const [loading, setLoading] = useState(false);
  const [tokenData, setTokenData] = useState("");
  const user = useGlobalStore();
  const { setAlert } = useAlert();
  const handleButtonClose = () => {
    setLoading(true);
    onClose();
  };

  useEffect(() => {
    setLoading(true)
    // GetTokenUseCase(campaignId).then((a) => {
    //   setTokenData(JSON.stringify(a.payload, null, 4));
    //   setLoading(false);
    // });
  }, []);

  return (
    <Container.box onClick={onClose}>
      <Container.warning>
        <Container.close src={close} alt="warning" />
        <Container.icon>
          <h3>Deletar campanhas</h3>
        </Container.icon>
        <TextArea aria-labelledby='' htmlFor='' id='testetset' name='' onChange={() => {}}>{tokenData}</TextArea>
        <Container.action>
          <SmallTransparentBlueButton onClick={handleButtonClose} loading={loading}>
            Copiar
          </SmallTransparentBlueButton>
        </Container.action>
      </Container.warning>
    </Container.box>
  );
};
