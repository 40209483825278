import styled from 'styled-components';
import { theme } from '../../../../../../themes/blue';
import { InputHTMLAttributes } from 'react';

const Container__Main = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  height: 100%;
  .error {
    color: ${(props) => props.theme.colors.red};
    font-weight: 400;
    font-size: ${(props) => props.theme.fontSize.smX};
    line-height: 18px;
  }
  .table-header {
    border: none;
    font-family: 'Poppins';
    color: white;
    background: #467dc0;
    border-bottom: none;
  }
  .cell-class {
    font-size: 12px !important;
  }
  .iconImage {
    width: 12px;
    height: 12px;
    object-fit: initial;
    border-radius: 0;
  }
`;

const Container__Input = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 6px;
`;

const Container__Header = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 8px;
  gap: 8px;
  @media (max-width: ${theme.media.mobile}) {
    flex-direction: column;
  }
`;

const Container__Content = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  align-items: flex-start;
  @media (max-width: ${theme.media.mobile}) {
    flex-direction: column;
  }
`;

const Container__Textarea = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  @media (max-width: ${theme.media.mobile}) {
    flex-direction: column;
  }
`;

const Container__Inputs = styled.div`
  display: flex;
  width: 100%;
  padding-top: 8px;
  gap: 8px;
  @media (max-width: ${theme.media.mobile}) {
    flex-direction: column;
  }
`;

const Container__Ods = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding-top: 8px;
  gap: 8px;
  @media (max-width: ${theme.media.mobile}) {
    flex-direction: column;
  }
`;

const Container__Button = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 8px 0;
`;

const Container__InputImage = styled.div`
  display: flex;
  width: 100%;
  padding-top: 8px;
  gap: 8px;
  flex-direction: column;
  @media (max-width: ${theme.media.mobile}) {
  
  }
`;

const Container__Preview = styled.div`
  margin-left: 16px;
  height: 100%;
  padding: 16px;
  background: ${(props) => props.theme.colors.background};
  box-shadow: 0px 2px 10px rgba(73, 80, 87, 0.16);
  border-radius: 16px;
  width: 100%;
  @media (max-width: ${theme.media.mobile}) {
    margin-left: 0px;
  }
`;


const Container__Cards = styled.div`
width: 100%;
  img {
    width: 100%;
    height: 400px;
    object-fit: contain;
    border-radius: 8px;
    @media (max-width: ${theme.media.monitorSmall}) {
      height: 300px;
    }
    @media (max-width: ${theme.media.smWatch}) {
      height: 200px;
    }
  }
`;
const Container__ImagesSmall = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 8px;
  flex-direction: row;
  padding: 16px;
  background: ${(props) => props.theme.colors.background};
  box-shadow: 0px 2px 10px rgba(73, 80, 87, 0.16);
  border-radius: 16px;
`; 
const Container__Small = styled.div`
    width: 24%;
  img {
    object-fit: cover;
    width: 100%;
    height: 100px;
    border-radius: 8px;
  }
`;

const Container__Image = styled.div`
  flex: 1;
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: center;
  label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${(props) => props.theme.colors.primary};
    border-radius: 2px;
    padding: 4px 8px;
    gap: 8px;
    color: ${(props) => props.theme.colors.white};
    cursor: pointer;
  }
  p {
    font-size: ${(props) => props.theme.fontSize.sm};
  }
  input {
    display: none;
  }
`;

const Container__Table = styled.div``;

const Container__Wrapper = styled.div`
display: flex;
width: 100%;
flex-direction: column;
`;

const Container__Box = styled.div`
width: 50%;
display: flex;
flex-direction: column;
@media (max-width: ${theme.media.mobile}) {
  width: 100%;
  }
`;

const Container__Images = styled.div`
display: flex;
gap: 16px;
@media (max-width: ${theme.media.mobile}) {
    flex-direction: column;
    width: 100%;
  }
`;

export const Container = {
  main: Container__Main,
  header: Container__Header,
  textarea: Container__Textarea,
  input: Container__Input,
  inputs: Container__Inputs,
  button: Container__Button,
  table: Container__Table,
  inputImage: Container__InputImage,
  preview: Container__Preview,
  image: Container__Image,
  content: Container__Content,
  wrapper: Container__Wrapper,
  box: Container__Box,
  cards: Container__Cards,
  small: Container__Small,
  imagesSmall: Container__ImagesSmall,
  images: Container__Images,
  ods: Container__Ods
};

const Text__Title = styled.h2`
  font-weight: 500;
  line-height: 24px;
`;

export const Text = {
  title: Text__Title,
};

export const _Input = styled.input<InputHTMLAttributes<HTMLInputElement>>``;
