import React, { useEffect, useState } from 'react'
import { Container } from './styles'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useAlert } from '../../../../infra/config/AlertContext/useAlert'
import { LayoutSidebar } from '../../../components/containers/LayoutSidebar'
import { GenerateHashInterface, GenerateHashUseCase } from '../../../../application/useCases/gooders/GenerateHashUseCase'
import { ListInstituion, ListInstitutionGoodersUseCase } from '../../../../application/useCases/institution/ListInstitutionUseCase'
import { TableListCampaign } from '../../../components/web3/TableListCampaignOrg'

const validationSchema = Yup.object().shape({
  count: Yup.string().required('Preencha a quantidade de hash'),
  goodersAmount: Yup.number().required(
    'Insira a quantidade de gooders'
  )
})

export const Web3Search: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [listInstitution, setListInstitution] = useState<ListInstituion[]>([]);
  const { setAlert } = useAlert()

  useEffect(() => {
    const fetchData = async () => {
      const { error, payload } = await ListInstitutionGoodersUseCase();
      if (!error && payload) {
        setListInstitution(payload.data);
      } else {
        setAlert(error!, 'error');
      }
    };
    fetchData();
  }, []);

  const formik = useFormik({
    initialValues: {
      count: '',
      goodersAmount: '',
      institutionId: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      console.log(values)
      setLoading(true)
      const dto: GenerateHashInterface = {
        count: +values.count,
        goodersAmount: +values.goodersAmount,
        institutionId: +values.institutionId
      }
      const { error } = await GenerateHashUseCase(dto)
      setLoading(false)
      if (error) {
        setAlert(error, 'error')
      } else {
        setAlert('Hash gerado com sucesso!', 'success')
        window.location.pathname = '/hash'
      }
    }
  })

  return (
    <LayoutSidebar>
      <Container.box>
        <Container.header>
          <Container.title >Hash</Container.title>
        </Container.header>
        <Container.main onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit(e);
        }}>
          <Container.title>Gerar sHash</Container.title>
          <TableListCampaign/>
        </Container.main>
      </Container.box>
    </LayoutSidebar>
  )
}

