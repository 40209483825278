import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Nav, Lines } from './styles';
import { handleLogout } from '../../../../../../application/handlers/HandleLogout';
import logo_blue from '../../../../../assets/logo/logo_blue.svg';
import menuBurguer from '../../../../../assets/icons/menuBurguer.svg'
import { NavIconLink } from '../../../NavIconLink/NavIconLink';
import { Logoff } from '../../../../../assets/icons/Logoff';
import { useGlobalStore } from '../../../../../../infra/state/GlobalStore';
import { Dashboard } from '../../../../../assets/icons/Dashboard';
import { ROUTES_PATH } from '../../../../../../infra/config/routes';
import { List } from '../../../../../assets/icons/List';
import { User } from '../../../../../assets/icons/User';
import { Report } from '../../../../../assets/icons/Report';
import { Discount } from '../../../../../assets/icons/Discount';
import { UserTypeEnum } from '../../../../../../domain/interfaces/User';
import close from '../../../../../assets/icons/close.svg'


interface SidebarItem {
    name: string;
    path: string;
    icon: React.ReactNode;
    dataTest: string;
}

export const Menuburguer = () => {
    const store = useGlobalStore().state;
    const { state } = useGlobalStore();
    const { pathname } = useLocation();
    const [mapRendered, setMapRendered] = useState(false);
    const [items, setItems] = useState<SidebarItem[]>([]);
    const [isOpen, setIsOpen] = useState(false)

    const handleSidebarToggle = () => {
        setIsOpen(!isOpen)
    }

    const userOrg: SidebarItem[] = [
        {
            name: 'Dashboard',
            path: '/dashboard',
            dataTest: 'navIconLink-DashboardOrg',
            icon: <Dashboard active={pathname.includes('/dashboard')} />,
        },
        {
            name: 'Gerenciar Campanhas',
            path: ROUTES_PATH.manageCampaign,
            dataTest: 'navIconLink-GerenciarCampanha',
            icon: <List active={pathname.includes(ROUTES_PATH.manageCampaign)} />,
        },
        {
            name: 'Gerenciar Usuários',
            path: '/gerenciar-usuarios',
            dataTest: 'navIconLink-GerenciarUsuarios',
            icon: <User active={pathname.includes('/gerenciar-usuarios')} />,
        },
        {
            name: 'Relatórios',
            path: '/report',
            dataTest: 'navIconLink-userOrgReport',
            icon: <Report active={pathname.includes('/report')} />,
        },
    ];

    const userInst: SidebarItem[] = [
        {
            name: 'Dashboard',
            path: '/dashboard',
            dataTest: 'navIconLink-DashboardInst',
            icon: <Dashboard active={pathname.includes('/dashboard')} />,
        },
        {
            name: 'Gerenciar Campanhas',
            path: ROUTES_PATH.manageCampaign,
            dataTest: 'navIconLink-GerenciarCampanha',
            icon: <List active={pathname.includes(ROUTES_PATH.manageCampaign)} />,
        },
        {
            name: 'Gerenciar Usuários',
            path: '/gerenciar-usuarios',
            dataTest: 'navIconLink-GerenciarUsuarios',
            icon: <User active={pathname.includes('/gerenciar-usuarios')} />,
        },
        {
            name: 'Relatórios',
            path: '/report',
            dataTest: 'navIconLink-userOrgReport',
            icon: <Report active={pathname.includes('/report')} />,
        },
    ];

    const userGoodersMember: SidebarItem[] = [
        {
            name: 'Dashboard',
            path: '/dashboard',
            dataTest: 'navIconLink-userGoodersMember',
            icon: <Dashboard active={pathname.includes('/dashboard')} />,
        },
        // {
        //   name: 'Relatórios',
        //   path: '/report',
        //   dataTest: 'navIconLink-userGoodersMemberReport',
        //   icon: <Report active={pathname.includes('/report')} />,
        // },
        {
            name: 'Gerenciar Campanhas',
            path: ROUTES_PATH.manageCampaign,
            dataTest: 'navIconLink-GerenciarCampanha',
            icon: <List active={pathname.includes(ROUTES_PATH.manageCampaign)} />,
        },
        {
            name: 'Gerenciar Recompensas',
            path: '/gerenciar-recompensas',
            dataTest: 'navIconLink-userGoodersMemberReportRewards',
            icon: <Discount active={pathname.includes('/gerenciar-recompensas')} />,
        },
        {
            name: 'Pesquisa Web3',
            path: '/pesquisa-web3',
            dataTest: 'navIconLink-userGoodersMemberReportRewards',
            icon: <Discount active={pathname.includes('/gerenciar-recompensas')} />,
        },
        {
            name: 'Gerenciar Usuários',
            path: '/gerenciar-usuarios',
            dataTest: 'navIconLink-GerenciarUsuarios',
            icon: <User active={pathname.includes('/gerenciar-usuarios')} />,
        },
    ];

    useEffect(() => {

        const checkACLAndUpdateSidebar = () => {
            if (state.acl.selectedACL?.type === UserTypeEnum.GOODERS) {
                return setItems([...userGoodersMember]);
            }
            if (state.acl.selectedACL !== null) {
                if (state.acl.selectedACL.type === UserTypeEnum.ORG) {
                    setItems([...userOrg]);
                } else if (state.acl.selectedACL.type === UserTypeEnum.INSTITUTION) {
                    setItems([...userInst]);
                }
            }
        };

        checkACLAndUpdateSidebar();
        setMapRendered(true);
    }, [state.acl.selectedACL]);

    return (
        <Container.main>
            <Container.burgerIcon src={isOpen ? close : menuBurguer} onClick={handleSidebarToggle} />
            <Container.menuMobile style={{ display: isOpen ? 'flex' : 'none' }} className="Mobile">
                <nav>
                    <ul>
                        {items.map((item, index) => (
                            <li key={index} className="navIconLink" data-test={item.dataTest}>
                                <NavIconLink
                                    icon={item.icon}
                                    redirectPath={item.path}
                                    active={pathname.includes(item.path)}
                                />
                                <Link to={item.path}>
                                    <Nav.text >{item.name}</Nav.text>
                                </Link>
                            </li>
                        ))}
                        <Lines className="line" />
                        {mapRendered && (
                            <div className="logout">
                                <li
                                    onClick={handleLogout}
                                    data-test="Icon-logoff"
                                    className="navIconMobile"
                                    style={{ cursor: 'pointer' }}
                                >
                                    <Logoff active={pathname.includes('/logoff')} />
                                    <Nav.text data-test="navIconLink-logoff" >
                                        Sair
                                    </Nav.text>
                                </li>
                            </div>
                        )}
                    </ul>
                    <ul >
                        <Lines className="line-bottom" />
                        <li >
                            <Nav.img>
                                <img src={logo_blue} alt="logo" />
                            </Nav.img>
                        </li>
                    </ul>
                </nav>
            </Container.menuMobile>
        </Container.main >
    );
};
