import { create, useStore } from 'zustand';

interface State {
  connectedWallet: null | string;
}

interface Actions {
  setConnectedWallet: (wallet: string | null) => void;
}

interface Web3State {
  state: State;
  actions: Actions;
}

export const Web3Store = create<Web3State>((set) => ({
  state: {
    connectedWallet: null,
  },
  actions: {
    setConnectedWallet: (wallet) =>
      set((prev) => ({
        state: { ...prev.state, connectedWallet: wallet },
      })),
  },
}));

export function useWeb3Store() {
  return useStore(Web3Store);
}
