
import { useEffect, useState } from "react";
import { GetReport, GetReportUseCase } from "../../../../../application/useCases/donation/GetReportUseCase";
import { useGlobalStore } from "../../../../../infra/state/GlobalStore";
import { useAlert } from "../../../../../infra/config/AlertContext/useAlert";
import { GridColDef, ptBR } from "@mui/x-data-grid";
import receipt from '../../../../assets/icons/report.svg';
import Table from "../../../common/Table";
import { Container } from '../TableAllInformation/styles'
import { dateSortComparator, dateValueFormatter, downloadPDF, formatWalletBalanceValue } from "../../../../../application/utils";
import { GetReceiptOrgInstUseCase } from "../../../../../application/useCases/donation/GetReceiptOrgInsttUseCase";
import { CardData, CardsTable } from "../../../common/CardsTable";

interface Props {
    useCase: GetReportUseCase;
    useCase2: GetReceiptOrgInstUseCase
}

export const TableDonationPay = ({ useCase, useCase2 }: Props) => {

    const [report, setReport] = useState<GetReport[]>([]);
    const { state } = useGlobalStore();
    const { setAlert } = useAlert();


    useEffect(() => {
        const fetchData = async () => {
            if (state.acl.selectedACL) {
                const orgId = state.acl.selectedACL.id;
                const institutionId = state.acl.selectedACL.id;
                const { error, payload } = await useCase.execute({ orgId, institutionId });
                if (!error && payload) {
                    const filteredData = payload.data.filter((item) => item.orderStatus === "SUCESSO");
                    setReport(filteredData);
                } else {
                    setAlert(error!, 'error');
                }
            }
        };
        fetchData();
    }, []);

    const orgId = state.acl.selectedACL?.id || 0
    const institutionId = state.acl.selectedACL?.id ?? 0

    async function downloadReportOnClick(orderId: string) {
        // Não conseguimos verificar a mensagem de erro porque o axios espera um array buffer, o único caso de erro é um 404 que é caso uma ordem não seja encontrada
        const { error, payload, statusCode } = await useCase2.execute({ orderId, orgId, institutionId });

        if (statusCode === 404) {
            return setAlert('Ordem de pagamento não encontrada', 'error');
        } else if (statusCode === 422) {
            return setAlert('Não é possivel visualizar o recibo de um doador anônimo!', 'error');
        } else if (error) {
            return setAlert(error, 'error');
        }

        downloadPDF(payload, 'recibo.pdf');
    }


    const columns: GridColDef[] = [
        {
            field: 'Usuário', headerName: 'Usuário', minWidth: 150, flex: 1, headerClassName: 'table-header',
            renderCell: (params) => {
                const userName = params.row['Usuário'];
                return userName ? userName : 'Doação Anônima';
            },
        },
        {
            field: 'Valor', headerName: 'Valor BRL', minWidth: 100, flex: 1, headerClassName: 'table-header',
        },
        // {
        //     field: 'Recibo', headerName: 'Recibo:', minWidth: 70, headerAlign: 'center', align: 'center', flex: 1, headerClassName: 'table-header',
        //     renderCell: (params) => {
        //         if (params.row['orderStatus'] === 'SUCESSO') {
        //             return (
        //                 <img
        //                     onClick={() => downloadReportOnClick(params.id.toString())}
        //                     style={{ cursor: 'pointer' }}
        //                     src={receipt}
        //                     alt="report"
        //                 />
        //             );
        //         } else {
        //             return <></>;
        //         }
        //     },
        //     sortable: false,
        //     filterable: false,
        // },
        {
            field: 'Tipo', headerName: 'Tipo', minWidth: 204.3, flex: 1, headerClassName: 'table-header',
        },
        { field: 'Campanha', headerName: 'Campanha', minWidth: 204.3, flex: 1, headerClassName: 'table-header', },
        {
            field: 'Data', headerName: 'Data', minWidth: 100, flex: 1, headerClassName: 'table-header',
            sortComparator: dateSortComparator,
            valueFormatter: dateValueFormatter,
        },
        {
            field: 'Doar Anônimo', headerName: 'Doar Anônimo', minWidth: 100.3, flex: 1, headerClassName: 'table-header',
        },

    ];

    const rows =
        (report?.map((item, index: number) => ({
            index: index,
            id: item.orderId,
            'Valor': formatWalletBalanceValue(item.amount),
            'Usuário': item.nameOfUser,
            'Tipo': item.isPerson ? 'Pessoa Física' : 'Pessoa Jurídica',
            'Campanha': item.campaign,
            'Doar Anônimo': item.anonymous ? 'Sim' : 'Não',
            'Data': new Date(item.createdAt),
            orderStatus: item.orderStatus
        })) ?? [])
            .sort((a, b) => a['Usuário'].localeCompare(b['Usuário']));

    const cardData: CardData[] = report.map((item) => ({
        id: item.orderId,
        title: [
            'Usuário',
            'Valor',
            'Tipo',
            'Campanha',
            'Data',
            'Doar Anônimo',
            'Status'
        ],
        value: [
            item.nameOfUser,
            formatWalletBalanceValue(item.amount),
            item.isPerson ? 'Pessoa Física' : 'Pessoa Jurídica',
            item.campaign,
            new Date(item.createdAt).toLocaleDateString('pt-BR'),
            item.anonymous ? 'Sim' : 'Não',
            item.orderStatus
        ],
    }));

    return (
        <>
            <Container.title> Doações monetárias - Sintético </Container.title>
            <Container.table>
                <Table rows={rows} columns={columns} menuIconDisplay="flex" numberRows={10} />
            </Container.table>
            <Container.mobile>
                <CardsTable cardData={cardData} />
            </Container.mobile>
        </>
    );
};