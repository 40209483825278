export const network = {
  1: {
    name: 'Ethereum Mainnet',
    chainId: '0x1',
    rpcUrls: ['https://rpc.ankr.com/eth', 'https://cloudflare-eth.com'],
    blockExplorerUrls: ['https://etherscan.io'],
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
  },
  5: {
    name: 'Ethereum Goerli Testnet',
    chainId: '0x5',
    rpcUrls: ['https://rpc.ankr.com/eth_goerli'],
    blockExplorerUrls: ['https://goerli.etherscan.io'],
    nativeCurrency: {
      name: 'Goerli ETH',
      symbol: 'gETH',
      decimals: 18,
    },
  },
  11155111: {
    name: 'Ethereum Sepolia Testnet',
    chainId: '0xa4b1a7',
    rpcUrls: ['https://rpc.sepolia.org'],
    blockExplorerUrls: ['https://sepolia.etherscan.io'],
    nativeCurrency: {
      name: 'Sepolia ETH',
      symbol: 'sepETH',
      decimals: 18,
    },
  },
  56: {
    name: 'Binance Smart Chain Mainnet',
    chainId: '0x38',
    rpcUrls: ['https://bsc-dataseed.binance.org/'],
    blockExplorerUrls: ['https://bscscan.com'],
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18,
    },
  },
  97: {
    name: 'Binance Smart Chain Testnet',
    chainId: '0x61',
    rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
    blockExplorerUrls: ['https://testnet.bscscan.com'],
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'tBNB',
      decimals: 18,
    },
  },
  137: {
    name: 'Polygon Mainnet',
    chainId: '0x89',
    rpcUrls: ['https://polygon-rpc.com'],
    blockExplorerUrls: ['https://polygonscan.com'],
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
  },
  80001: {
    name: 'Polygon Mumbai Testnet',
    chainId: '0x13881',
    rpcUrls: ['https://rpc-mumbai.maticvigil.com'],
    blockExplorerUrls: ['https://mumbai.polygonscan.com'],
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
  },
  80002: {
    chainId: '0x13882',
    chainName: 'Polygon Amoy Testnet',
    rpcUrls: ['https://rpc-amoy.polygon.technology/'], // Substitua pela URL real do RPC
    nativeCurrency: {
      name: 'Polygon Test Token',
      symbol: 'POL',
      decimals: 18,
    },
    blockExplorerUrls: ['https://amoy.polygonscan.com/'], // Substitua pela URL real do explorador
  },
  43114: {
    name: 'Avalanche Mainnet',
    chainId: '0xa86a',
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://snowtrace.io'],
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18,
    },
  },
  43113: {
    name: 'Avalanche Fuji Testnet',
    chainId: '0xa869',
    rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://testnet.snowtrace.io'],
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18,
    },
  },
  250: {
    name: 'Fantom Opera',
    chainId: '0xfa',
    rpcUrls: ['https://rpc.ftm.tools'],
    blockExplorerUrls: ['https://ftmscan.com'],
    nativeCurrency: {
      name: 'Fantom',
      symbol: 'FTM',
      decimals: 18,
    },
  },
  4002: {
    name: 'Fantom Testnet',
    chainId: '0xfa2',
    rpcUrls: ['https://rpc.testnet.fantom.network'],
    blockExplorerUrls: ['https://testnet.ftmscan.com'],
    nativeCurrency: {
      name: 'Fantom',
      symbol: 'FTM',
      decimals: 18,
    },
  },
  10: {
    name: 'Optimism Mainnet',
    chainId: '0xa',
    rpcUrls: ['https://mainnet.optimism.io'],
    blockExplorerUrls: ['https://optimistic.etherscan.io'],
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
  },
  420: {
    name: 'Optimism Goerli Testnet',
    chainId: '0x1a4',
    rpcUrls: ['https://goerli.optimism.io'],
    blockExplorerUrls: ['https://goerli-optimism.etherscan.io'],
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
  },
  42161: {
    name: 'Arbitrum One',
    chainId: '0xa4b1',
    rpcUrls: ['https://arb1.arbitrum.io/rpc'],
    blockExplorerUrls: ['https://arbiscan.io'],
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
  },
  421613: {
    name: 'Arbitrum Goerli Testnet',
    chainId: '0x66eed',
    rpcUrls: ['https://goerli-rollup.arbitrum.io/rpc'],
    blockExplorerUrls: ['https://goerli.arbiscan.io'],
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
  },
};
