import { useEffect, useState } from 'react';
import { Container, Text } from './styles';
import { LabelInputFormCampaign } from '../../../common/Input';
import { TextArea } from '../../../common/TextArea';
import { useGlobalCampaignUnique } from '../../../../../infra/state/useCampaign';
import { SmallBlueButton } from '../../../common/Button';
import { UpdateDonationMoneyForCampaignUseCase } from '../../../../../application/useCases/campaign/donation/UpdateDonationMoneyForCampaignUseCase';
import { useAlert } from '../../../../../infra/config/AlertContext/useAlert';
import { useFormik } from 'formik';
import { validationSchema } from '../../gerenciar-campanha/Create/component/DonationPay/index';
import { useGlobalStore } from '../../../../../infra/state/GlobalStore';
import { divideBy100 } from '../../../../../application/utils';

interface Props {
  useCase: UpdateDonationMoneyForCampaignUseCase;
}

export const DonationPayEdit = ({ useCase }: Props) => {
  const { campaignUnique } = useGlobalCampaignUnique();
  const users = useGlobalStore();
  const [loading, setLoading] = useState(false);
  const { setAlert } = useAlert();

  const { values, touched, errors, handleBlur, handleChange, handleSubmit, setValues } = useFormik({
    initialValues: {
      description: '',
      multiplier: '',
      target: '',
      isActive: true,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);

      if (!campaignUnique || !users.state.acl.selectedACL) {
        setAlert('Não foi possível alterar a campanha no momento', 'error');
        return;
      }
      const campaignId = campaignUnique.id;
      const institutionId = campaignUnique.institution.id;
      const orgId = users.state.acl.selectedACL.id;

      const { error } = await useCase.execute(
        { orgId, institutionId, campaignId },
        {
          ...values,
          multiplier: +values.multiplier,
          target: +values.target,
          isActive: values.isActive,
        }
      );
      if (!error) {
        setAlert('Campanha editada com sucesso', 'success');
      } else {
        setAlert(error, 'error');
      }
      setLoading(false);
    },
  });

  const handleCheckboxChange = async () => {
    // Update the isActive value in formik
    const updatedValues = { ...values, isActive: !values.isActive };
    setValues(updatedValues);

    // Call the handleSubmit function to send the updated values to the backend
    await handleSubmit();
  };

  useEffect(() => {
    if (campaignUnique) {
      setValues({
        description: campaignUnique.moneyDonationOptions?.description ?? '',
        multiplier: campaignUnique.moneyDonationOptions?.multiplier.toString() ?? '',
        target: divideBy100(campaignUnique.moneyDonationOptions?.target ?? 0).toString(),
        isActive: campaignUnique.moneyDonationOptions?.isActive ?? true,
      });
    }
  }, [campaignUnique]);

  return (
    <Container.main onSubmit={handleSubmit}>
      <Container.input>
        <Container.header isActive={values.isActive}>
          <Text.title>Doação Financeira</Text.title>
          <label>
            <input
              type="checkbox"
              placeholder="Ativo"
              id="isActive"
              name="isActive"
              onChange={handleCheckboxChange}
              checked={values.isActive}
              aria-labelledby="isActive"
            />{' '}
            {values.isActive ? 'Desativar' : 'Ativar'}
          </label>
        </Container.header>
        <Container.box>
          <Container.inputs>
            <h3 style={{ color: 'gray' }}>Meta de arrecadação:</h3>
            <LabelInputFormCampaign
              htmlFor="target"
              id="target"
              name="target"
              type="number"
              placeholder="R$ 0,00"
              value={values.target}
              onChange={handleChange}
              aria-labelledby="Valor"
              onBlur={handleBlur}
              error={touched.target && errors.target ? errors.target : undefined}
            >
              Valor
            </LabelInputFormCampaign>
            <LabelInputFormCampaign
              htmlFor="multiplier"
              id="multiplier"
              name="multiplier"
              type="number"
              placeholder=""
              value={values.multiplier}
              onChange={handleChange}
              aria-labelledby="Multiplicador Gooders"
              onBlur={handleBlur}
              error={touched.multiplier && errors.multiplier ? errors.multiplier : undefined}
            >
              Multiplicador Gooders
            </LabelInputFormCampaign>
          </Container.inputs>
          <Container.inputs style={{ marginTop: '40px' }}>
            <TextArea
              htmlFor="description"
              id="description"
              name="description"
              rows={4}
              maxLength={500}
              placeholder="Descrição de uso"
              value={values.description}
              onChange={handleChange}
              aria-labelledby="Descrição de uso"
              onBlur={handleBlur}
              error={touched.description && errors.description ? errors.description : undefined}
            >
              {' '}
              Descrição sobre a meta
            </TextArea>
          </Container.inputs>
        </Container.box>
      </Container.input>
      <SmallBlueButton type="submit" loading={loading}>
        Salvar
      </SmallBlueButton>
    </Container.main>
  );
};
