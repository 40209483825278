import React, { useCallback, useEffect } from 'react';
import styles from './styles.module.css';
import { network } from './models';
import { useWeb3Store } from '../../../../infra/state/Web3Store'; // Certifique-se de ajustar o caminho para o seu store
import { useAlert } from '../../../../infra/config/AlertContext/useAlert';

interface MetamaskButtonProps {
  onConnect?: (address: string) => void;
  onDisconnect?: () => void;
  className?: string;
  buttonText?: string;
  chainId: keyof typeof network;
}

interface RequestArguments {
  method: string;
  params?: unknown[];
}

interface EthereumEventMap {
  accountsChanged: (accounts: string[]) => void;
  chainChanged: (chainId: string) => void;
  connect: (connectInfo: { chainId: string }) => void;
  disconnect: (error: { code: number; message: string }) => void;
}

interface Ethereum {
  isMetaMask?: boolean;
  request: (args: RequestArguments) => Promise<unknown>;
  on?<K extends keyof EthereumEventMap>(event: K, handler: EthereumEventMap[K]): void;
  removeListener?<K extends keyof EthereumEventMap>(event: K, handler: EthereumEventMap[K]): void;
}

declare global {
  interface Window {
    ethereum?: Ethereum;
  }
}

const MetamaskButton: React.FC<MetamaskButtonProps> = ({
  onConnect,
  onDisconnect,
  className,
  buttonText = 'Conectar',
  chainId,
}) => {
  const {
    state: { connectedWallet },
    actions: { setConnectedWallet },
  } = useWeb3Store();

  const { setAlert } = useAlert();

  const chainParams = network[chainId];

  useEffect(() => {
    const checkNetworkAndConnect = async () => {
      if (window.ethereum) {
        try {
          const requestedChainID = (await window.ethereum.request({
            method: 'eth_chainId',
          })) as string;

          if (requestedChainID === chainParams.chainId) {
            const accounts = (await window.ethereum.request({
              method: 'eth_requestAccounts',
            })) as string[];
            const address = accounts[0];
            setConnectedWallet(address);
            onConnect?.(address);
          }
        } catch (error) {
          console.error('Falha ao verificar a rede e conectar a carteira', error);
        }
      }
    };
    checkNetworkAndConnect();
  }, [onConnect, chainParams.chainId, setConnectedWallet]);

  const handleConnect = async () => {
    if (window.ethereum) {
      try {
        const accounts = (await window.ethereum.request({
          method: 'eth_requestAccounts',
        })) as string[];
        const address = accounts[0];

        const requestedChainID = (await window.ethereum.request({
          method: 'eth_chainId',
        })) as string;
        if (requestedChainID !== chainParams.chainId) {
          try {
            await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: chainParams.chainId }],
            });
          } catch (switchError) {
            if ((switchError as { code: number }).code === 4902) {
              console.log('chainParams', chainParams);
              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [chainParams],
              });
            } else {
              console.error('Falha ao trocar de rede', switchError);
              return;
            }
          }
        }
        setConnectedWallet(address);
        onConnect?.(address);
        setAlert('Carteira conectada!', 'success');
      } catch (error) {
        console.error('Falha ao conectar a carteira', error);
      }
    } else {
      alert('Por favor, instale o MetaMask!');
    }
  };

  const handleDisconnect = useCallback(() => {
    setAlert('Carteira desconectada', 'info');
    setConnectedWallet(null);
    onDisconnect?.();
  }, [onDisconnect, setConnectedWallet]);

  useEffect(() => {
    if (window.ethereum) {
      const handleAccountsChanged = (accounts: string[]) => {
        if (accounts.length === 0) {
          handleDisconnect();
        } else {
          setConnectedWallet(accounts[0]);
          onConnect?.(accounts[0]);
        }
      };

      const handleChainChanged = (chainId: string) => {
        console.log('Chain changed to', chainId);
        window.location.reload();
      };

      window.ethereum.on?.('accountsChanged', handleAccountsChanged);
      window.ethereum.on?.('chainChanged', handleChainChanged);

      return () => {
        window.ethereum?.removeListener?.('accountsChanged', handleAccountsChanged);
        window.ethereum?.removeListener?.('chainChanged', handleChainChanged);
      };
    }
  }, [onConnect, handleDisconnect, setConnectedWallet]);

  return (
    <button
      className={`${styles.button} ${className ?? ''}`}
      onClick={connectedWallet ? handleDisconnect : handleConnect}
    >
      {connectedWallet ? `Desconectar` : buttonText}
    </button>
  );
};

export default MetamaskButton;
