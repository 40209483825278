import { GridColDef } from '@mui/x-data-grid';
import update from '../../../../../assets/icons/update.svg';
import delet from '../../../../../assets/icons/delet.svg';
import { Link } from 'react-router-dom';
import { CampaignLinkedWith } from '../../../../../../application/useCases/campaign/GetCampaignsLinkedWithOrg';
import { dateSortComparator, dateValueFormatter } from '../../../../../../application/utils';

interface ColumnsTableListCampaignProps {
  setSelectedPost: (post: CampaignLinkedWith) => void;
  setOpenModal: (open: boolean) => void;
}

export const ColumnsTableListCampaign = ({
  setSelectedPost,
  setOpenModal,
}: ColumnsTableListCampaignProps): GridColDef[] => {

  return [
    { field: 'Título', headerName: 'Título', minWidth: 180, flex: 1, headerClassName: 'table-header', },
    //{ field: 'Local', headerName: 'Local', minWidth: 220, flex: 1, headerClassName: 'table-header' },
    // {
    //   field: 'Data', headerName: 'Data de criação', minWidth: 100, flex: 1, headerAlign: 'center', align: 'center', headerClassName: 'table-header',
    //   sortComparator: dateSortComparator,
    //   valueFormatter: dateValueFormatter,
    // },
    // {
    //   field: 'Status', headerName: 'Status', minWidth: 100, flex: 1, headerAlign: 'center', align: 'center', headerClassName: 'table-header',
    //   renderCell: (params) => (
    //     <span style={{ color: params.value === 'Ativa' ? 'green' : 'red', fontSize: "12px", fontWeight: "600" }}>
    //       {params.value}
    //     </span>
    //   ),
    // },
    {
      field: 'Edit', headerName: 'Ações', minWidth: 60, flex: 1, headerAlign: 'center', align: 'center', headerClassName: 'table-header',
      renderCell: (params) => {
        return (
          <>
            <img
              onClick={() => {
                setSelectedPost(params.row);
                setOpenModal(true);
              }}
              src={delet}
              alt="action"
              style={{ cursor: 'pointer', }}
            />
          </>
        );
      },
      sortable: false,
      filterable: false,
    },
  ];
};
