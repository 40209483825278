import { HttpClient } from '../../HttpClient';

export interface Token {
  balance: number;
  creator: string;
  expires_in: number;
  name: string;
  ods: number[]; // Array de números
  owner: string; // Endereço no formato hexadecimal
  timestamp: number; // Epoch timestamp
  tokenId: number; // Identificador único do token
}

export async function GetTokenUseCase(campaignIdOrTokenId?: string) {

  if (campaignIdOrTokenId === "") {
    const response = await HttpClient<any, { tokens: Token[] }>(
      'post',
      '/api_oracle/read/searchTokens'
    );
  
    return response;
  }

  if (!isNaN(Number(campaignIdOrTokenId))) {
    const response = await HttpClient<any, { tokens: Token[] }>(
      'post',
      '/api_oracle/read/searchTokens',
      {
        tokenId: Number(campaignIdOrTokenId),
      }
    );
    return response;
  }

  if (campaignIdOrTokenId) {
    const response = await HttpClient<any, { tokens: Token[] }>(
      'post',
      '/api_oracle/read/searchTokens',
      {
        name: campaignIdOrTokenId,
      }
    );
    return response;
  }

  const response = await HttpClient<any, { tokens: Token[] }>(
    'post',
    '/api_oracle/read/searchTokens',
    {
      name: campaignIdOrTokenId,
    }
  );

  return response;
}
