import { Container, Input, SearchIcon } from '../../styles'
import search_glass from '../../../../../assets/icons/search_glass.svg';

interface SearchListCampaignProps {
    search: string;
    searchText: (event: React.ChangeEvent<HTMLInputElement>) => void;
}


export const SearchTableListCampaign: React.FC<SearchListCampaignProps> = ({
    search,
    searchText,
}) => {
    return (
        <Container.search>
            <Input.label htmlFor="search-input">
                <SearchIcon>
                    <img src={search_glass} alt="" />
                </SearchIcon>
                <Input.field
                    id="search-input"
                    type="text"
                    placeholder="Pesquisar"
                    value={search}
                    onChange={searchText}
                />
            </Input.label>
        </Container.search>

    )
}