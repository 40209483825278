import { ChangeEvent, useEffect, useState } from 'react';
import { Container, Text } from '../../../../pages/gooders/editar-campanha/styles';
import { LabelInputFormCampaign } from '../../../common/Input';
import { TextArea } from '../../../common/TextArea';
import { useGlobalCampaignUnique } from '../../../../../infra/state/useCampaign';
import { SmallBlueButton } from '../../../../components/common/Button';
import { Formik, useFormik } from 'formik';
import { useAlert } from '../../../../../infra/config/AlertContext/useAlert';
import { SelectInput } from '../../../../components/common/SelectInput';
import { useGlobalStore } from '../../../../../infra/state/GlobalStore';
import { Container_Title_And_SwitchBTN } from './styles';
import { ChangeActiveStatus } from './components/ChangeActiveStatus';
import { UpdateCampaignInformationUseCase } from '../../../../../application/useCases/campaign/UpdateCampaignInformation';
import { UpdateActiveStatusOfCampaignUseCase } from '../../../../../application/useCases/org/campaign/OrgUpdateActiveStatusOfCampaign';
import {
  selectOptionsOds,
  selectOptionsState,
} from '../../../common/SelectInput/components/SelectOptionsState';
import {
  Ods,
  odsONU,
  validationSchema,
} from '../../gerenciar-campanha/Create/component/CreateCause';
import { Autocomplete, Chip, TextField } from '@mui/material';
import { theme } from '../../../../themes/blue';

interface Props {
  useCase1: UpdateCampaignInformationUseCase;
  useCase2: UpdateActiveStatusOfCampaignUseCase;
}

export const InformationEdit = ({ useCase1, useCase2 }: Props) => {
  const { campaignUnique } = useGlobalCampaignUnique();

  const { setAlert } = useAlert();
  const [loading, setLoading] = useState(false);
  const [ods, setOds] = useState<Ods[]>([]);
  const users = useGlobalStore();
  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: {
      institutionName: '',
      title: '',
      summary: '',
      description: '',
      responsible: '',
      telephone: '',
      address: '',
      city: '',
      state: '',
      institutionId: '0',
      ods: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      if (!campaignUnique || !users.state.acl.selectedACL) {
        setAlert('Não foi possível adicionar o item no momento', 'error');
        return;
      }
      const campaignId = campaignUnique.id;
      const institutionId = campaignUnique.institution.id;
      const orgId = users.state.acl.selectedACL.id;

      const valuesWithResponsibleTelephone = {
        ...values,
        ods: ods.map((o) => +o.value),
        responsibleTelephone: values.telephone,
      };

      const { error } = await useCase1.execute(
        { orgId, institutionId, campaignId },
        valuesWithResponsibleTelephone
      );
      if (!error) {
        setAlert('Campanha editada com sucesso', 'success');
      } else {
        setAlert(error, 'error');
      }
      setLoading(false);
    },
  });

  const handleOdsAdd = (event: ChangeEvent<HTMLSelectElement>) => {
    const odsId = Number(event.currentTarget.value);
    const alreadyContainOds = ods.find((ods) => ods.value === odsId);
    if (alreadyContainOds) return;
    const Ods = odsONU.find((ods) => ods.value === odsId)!;
    setOds((ods) => [...ods, Ods]);
    handleChange(event);
  };

  const handleOdsDelete = (odsId: number) => {
    setOds((ods) => {
      const odsWillBeEmpty = ods?.length - 1 === 0;
      if (odsWillBeEmpty) {
        setFieldValue('ods', '', true);
      }
      return ods.filter((ods) => ods.value !== odsId);
    });
  };

  useEffect(() => {
    if (campaignUnique) {
      const campaignOds = campaignUnique.ods.map((od) => odsONU[od.odsId - 1]);
      setOds(campaignOds);
      setValues({
        institutionName: campaignUnique.institution.name,
        title: campaignUnique.title,
        summary: campaignUnique.summary,
        description: campaignUnique.description,
        responsible: campaignUnique.responsible,
        telephone: campaignUnique.responsibleTelephone ?? '',
        address: campaignUnique.address ?? '',
        city: campaignUnique.city,
        state: campaignUnique.state,
        institutionId: campaignUnique.institution.id.toString(),
        ods: odsONU[0].label,
      });
    }
  }, [campaignUnique]);

  return (
    <Container.main onSubmit={handleSubmit}>
      <Container_Title_And_SwitchBTN>
        <Text.title>Informações básicas</Text.title>
        <ChangeActiveStatus useCase={useCase2} />
      </Container_Title_And_SwitchBTN>
      <Container.input>
        <Container.name>
          <LabelInputFormCampaign
            htmlFor="institutionName"
            id="institutionName"
            name="institutionName"
            type="text"
            maxLength={25}
            placeholder=""
            disabled
            aria-labelledby="Name Instituition"
            value={values.institutionName}
            onChange={() => {}}
          >
            Nome Instituição
          </LabelInputFormCampaign>
          <LabelInputFormCampaign
            htmlFor="title"
            id="title"
            name="title"
            type="text"
            maxLength={25}
            placeholder=""
            value={values.title}
            onChange={handleChange}
            aria-labelledby="title"
            onBlur={handleBlur}
            error={touched.title && errors.title ? errors.title : undefined}
          >
            Título *
          </LabelInputFormCampaign>
        </Container.name>
        <Container.textarea>
          <TextArea
            htmlFor="summary"
            id="summary"
            name="summary"
            rows={3}
            maxLength={255}
            placeholder=""
            value={values.summary}
            onChange={handleChange}
            aria-labelledby="Resumo da campanha"
            onBlur={handleBlur}
            error={touched.summary && errors.summary ? errors.summary : undefined}
          >
            Resumo da Campanha *
          </TextArea>
          <TextArea
            htmlFor="description"
            id="description"
            name="description"
            rows={3}
            maxLength={700}
            placeholder=""
            value={values.description}
            onChange={handleChange}
            aria-labelledby="Descrição do projeto"
            onBlur={handleBlur}
            error={touched.description && errors.description ? errors.description : undefined}
          >
            Descrição do projeto *
          </TextArea>
        </Container.textarea>
        <Container.inputs>
          <LabelInputFormCampaign
            htmlFor="responsible"
            id="responsible"
            name="responsible"
            type="text"
            maxLength={25}
            placeholder=""
            value={values.responsible}
            onChange={handleChange}
            aria-labelledby="responsible"
            onBlur={handleBlur}
            error={touched.responsible && errors.responsible ? errors.responsible : undefined}
          >
            Pessoa responsável *
          </LabelInputFormCampaign>
          <LabelInputFormCampaign
            htmlFor="telephone"
            id="telephone"
            name="telephone"
            type="text"
            placeholder=""
            value={values.telephone}
            onChange={handleChange}
            aria-labelledby="telephone"
            onBlur={handleBlur}
            error={touched.telephone && errors.telephone ? errors.telephone : undefined}
          >
            Telefone
          </LabelInputFormCampaign>
          <SelectInput
            htmlFor="ods"
            id="ods"
            name="ods"
            placeholder=""
            value={values.ods.length > 0 ? values.ods[values.ods.length - 1] : ''}
            onBlur={handleBlur}
            onChange={handleOdsAdd}
            options={[...odsONU]}
            aria-labelledby="Categoria ODS"
            error={touched.ods && errors.ods ? errors.ods.toString() : undefined}
          >
            {"Categoria ODS (Selecione um ou mais) *"}
          </SelectInput>
        </Container.inputs>
        <Container.ods>
            {ods.map((ods, i) => (
              <Chip
                key={i}
                label={ods.label}
                onDelete={(ev) => handleOdsDelete(ods.value)}
                sx={{ backgroundColor: theme.colors.primary }}
                color="primary"
                style={{ fontWeight: 'bold' }}
              />
            ))}
          </Container.ods>
        <Container.inputs>
          <LabelInputFormCampaign
            htmlFor="address"
            id="address"
            name="address"
            type="text"
            placeholder=""
            value={values.address}
            onChange={handleChange}
            aria-labelledby="Endereço"
            onBlur={handleBlur}
            error={touched.address && errors.address ? errors.address : undefined}
          >
            Endereço
          </LabelInputFormCampaign>
        </Container.inputs>
        <Container.inputs>
          <LabelInputFormCampaign
            htmlFor="city"
            id="city"
            name="city"
            type="text"
            placeholder={campaignUnique?.city}
            value={values.city}
            onChange={handleChange}
            aria-labelledby="Cidade"
            onBlur={handleBlur}
            error={touched.city && errors.city ? errors.city : undefined}
          >
            Cidade *
          </LabelInputFormCampaign>
          <SelectInput
            htmlFor="state"
            id="state"
            name="state"
            options={[...selectOptionsState]}
            placeholder={campaignUnique?.state}
            value={values.state}
            onChange={handleChange}
            aria-labelledby="Estado"
            onBlur={handleBlur}
            error={touched.state && errors.state ? errors.state : undefined}
          >
            Estado *
          </SelectInput>
        </Container.inputs>
      </Container.input>
      <SmallBlueButton type="submit" loading={loading}>
        Salvar
      </SmallBlueButton>
    </Container.main>
  );
};
