import { UserTypeEnum } from '../../../domain/interfaces/User';
import { HttpClient } from '../../HttpClient';
import { SharedUseCase } from './item/CreateItemForCampaignUseCase';

export interface UpdateCampaignInformationDto {
  title: string;
  summary: string;
  description: string;
  responsible: string;
  responsibleTelephone: string;
  address: string;
  city: string;
  state: string;
  ods: number[]
}

interface Params {
  orgId: number;
  institutionId: number;
  campaignId: number;
}

export class UpdateCampaignInformationUseCase extends SharedUseCase<
  Params,
  UpdateCampaignInformationDto,
  {
    payload: void | null;
    error: string | null;
  }
> {
  constructor(entity: UserTypeEnum) {
    super(entity, { hasDto: true, hasParams: true });
  }

  protected override async execInstitution() {
    return await HttpClient<UpdateCampaignInformationDto, void>(
      'put',
      `/campaigns/institution/${this.params!.institutionId}/${this.params!.campaignId}`,
      this.dto!
    );
  }
// falta rota
  protected override async execOrg() {
    return await HttpClient<UpdateCampaignInformationDto, void>(
      'put',
      `/campaigns/org/${this.params!.orgId}/institution/${this.params!.institutionId}/${
        this.params!.campaignId
      }`,
      this.dto!
    );
  }


  protected override async execGooders() {
    return await HttpClient<UpdateCampaignInformationDto, void>(
      'put',
      `/campaigns/gooders/${this.params!.campaignId}`,
      this.dto!
    );
}
}
