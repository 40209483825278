import { createBrowserRouter, RouterProvider as ReactRouterDomProvider } from 'react-router-dom';
import App from '../../App';
import {
  AclRouteHandler,
  HandleComponent,
  ProtectedRoute,
} from '../../ui/components/others/ProtectedRoute';
import { NotFound } from '../../ui/pages/notfound';
import { CampaignUnit } from '../../ui/pages/users/campaign';
import ReturnPay from '../../ui/pages/users/ReturnPay';
import { LoginUser } from '../../ui/pages/users/login/components/LoginUser';
import { Profile } from '../../ui/pages/users/profile';
import { Home } from '../../ui/pages/users/home';
import { FormContext } from '../../ui/pages/users/CreateAccount/components/context/formContext';
import { CreateAccount } from '../../ui/pages/users/CreateAccount';
import { CreateAcess } from '../../ui/pages/users/CreateAcess';
import { NewPassword } from '../../ui/pages/users/redefinir-senha';
import { RequestRecoveryPassword } from '../../ui/pages/users/requestRecoveryPassword';
import { UserRoleEnum, UserTypeEnum } from '../../domain/interfaces/User';
import { DashboardGoodersMember } from '../../ui/pages/gooders/dashboard';
import { DashboardInst } from '../../ui/pages/institution/dashboard';
import { DashboardOrg } from '../../ui/pages/org/dashboard';
import { LayoutSidebar } from '../../ui/components/containers/LayoutSidebar';
import { GerenciarCampanhas } from '../../ui/pages/org/gerenciar-campanhas';
import { ListCampaigns } from '../../ui/pages/users/campaigns';
import { EditarCampanha } from '../../ui/pages/org/editar-campanha';
import { InstitutionGerenciarCampanhas } from '../../ui/pages/institution/gerenciar-campanhas';
import { InstitutionEditarCampanha } from '../../ui/pages/institution/editar-campanha';
import { CreateUserType } from '../../ui/pages/users/createusertype';
import { Report } from '../../ui/pages/gooders/report';
import { GerenciarRecompensas } from '../../ui/pages/gooders/gerenciar-recompensas';
import { GoodersGerenciarCampanhas } from '../../ui/pages/gooders/gerenciar-campanhas';
import { Rewards } from '../../ui/pages/users/rewards';
import { GetDahsboardUseCase } from '../../application/useCases/dahsboard/OrgDashboardUseCase';
import { ReportOrg } from '../../ui/pages/org/report';
import { GerenciarUser } from '../../ui/pages/gooders/gerenciar-usuarios';
import { ReportInst } from '../../ui/pages/institution/report';
import { GoodersEditarCampanha } from '../../ui/pages/gooders/editar-campanha';
import { GerenciarUserOrgs } from '../../ui/pages/org/gerenciar-usuarios';
import { GerenciarUserInst } from '../../ui/pages/institution/gerenciar-usuarios';
import { CheckOutPay } from '../../ui/pages/users/checkOutPay';
import { Hash } from '../../ui/pages/gooders/hash';
import { GenerateHash } from '../../ui/pages/gooders/generate-hash';
import { RescueHash } from '../../ui/pages/users/rescue-hash';
import { Web3Search } from '../../ui/pages/gooders/pesquisa-web3';

export const ROUTES_PATH = {
  manageCampaign: '/gerenciar-campanhas',
  listCampaignsForUsers: '/campanhas',
};

export const RouterProvider = () => {
  return <ReactRouterDomProvider router={routes} />;
};

const routes = createBrowserRouter([
  {
    path: '/',
    element: <App />,
  },
  {
    path: '/login',

    element: <LoginUser />,
  },
  {
    path: '/createaccount',
    element: (
      <FormContext>
        <CreateAccount />
      </FormContext>
    ),
  },
  {
    path: '/request-recovery-password',
    element: <RequestRecoveryPassword />,
  },
  {
    path: '/redefinir-senha/:path',
    element: <NewPassword />,
  },

  {
    path: '/returnpay',
    element: <ReturnPay />,
  },
  {
    path: '/*',
    element: <NotFound />,
  },
  // private
  {
    path: '/campanhas/:path',
    element: (
      <ProtectedRoute>
        <CampaignUnit />
      </ProtectedRoute>
    ),
  },
  {
    path: '/createacess',
    element: (
      <ProtectedRoute>
        <CreateAcess />
      </ProtectedRoute>
    ),
  },
  {
    path: '/home',
    element: (
      <ProtectedRoute>
        <Home />
      </ProtectedRoute>
    ),
  },
  {
    path: '/profile',
    element: (
      <ProtectedRoute>
        <Profile />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES_PATH.listCampaignsForUsers,
    element: (
      <ProtectedRoute>
        <ListCampaigns />
      </ProtectedRoute>
    ),
  },
  {
    path: '/checkout',
    element: <CheckOutPay />,
  },
  {
    path: '/createuser',
    element: (
      <ProtectedRoute>
        <HandleComponent
          components={{
            GOODERS: (
              <AclRouteHandler minRoleLvl={UserRoleEnum.VOLUNTEER}>
                <CreateUserType />
              </AclRouteHandler>
            ),
            ORG: (
              <AclRouteHandler minRoleLvl={UserRoleEnum.VOLUNTEER}>
                <CreateUserType />
              </AclRouteHandler>
            ),
          }}
        />
      </ProtectedRoute>
    ),
  },
  {
    path: '/recompensas',
    element: (
      <ProtectedRoute>
        <Rewards />
      </ProtectedRoute>
    ),
  },
  {
    path: '/recompensas/:path',
    element: (
      <ProtectedRoute>
        <CampaignUnit />
      </ProtectedRoute>
    ),
  },
  {
    path: '/dashboard',
    element: (
      <ProtectedRoute>
        <HandleComponent
          components={{
            GOODERS: (
              <AclRouteHandler minRoleLvl={UserRoleEnum.VOLUNTEER}>
                <LayoutSidebar>
                  <DashboardGoodersMember />
                </LayoutSidebar>
              </AclRouteHandler>
            ),
            INSTITUTION: (
              <AclRouteHandler minRoleLvl={UserRoleEnum.VOLUNTEER}>
                <LayoutSidebar>
                  <DashboardInst />
                </LayoutSidebar>
              </AclRouteHandler>
            ),
            ORG: (
              <AclRouteHandler minRoleLvl={UserRoleEnum.VOLUNTEER}>
                <LayoutSidebar>
                  <DashboardOrg useCase={new GetDahsboardUseCase(UserTypeEnum.ORG)} />
                </LayoutSidebar>
              </AclRouteHandler>
            ),
          }}
        />
      </ProtectedRoute>
    ),
  },
  {
    path: '/report',
    element: (
      <ProtectedRoute>
        <HandleComponent
          components={{
            GOODERS: (
              <AclRouteHandler minRoleLvl={UserRoleEnum.VOLUNTEER}>
                <Report />
              </AclRouteHandler>
            ),
            ORG: (
              <AclRouteHandler minRoleLvl={UserRoleEnum.VOLUNTEER}>
                <ReportOrg />
              </AclRouteHandler>
            ),
            INSTITUTION: (
              <AclRouteHandler minRoleLvl={UserRoleEnum.VOLUNTEER}>
                <ReportInst />
              </AclRouteHandler>
            ),
          }}
        />
      </ProtectedRoute>
    ),
  },
  {
    path: '/gerenciar-recompensas',
    element: (
      <ProtectedRoute>
        <HandleComponent
          components={{
            GOODERS: (
              <AclRouteHandler minRoleLvl={UserRoleEnum.VOLUNTEER}>
                <GerenciarRecompensas />
              </AclRouteHandler>
            ),
          }}
        />
      </ProtectedRoute>
    ),
  },
  {
    path: '/gerenciar-usuarios',
    element: (
      <ProtectedRoute>
        <HandleComponent
          components={{
            GOODERS: (
              <AclRouteHandler minRoleLvl={UserRoleEnum.VOLUNTEER}>
                <GerenciarUser />
              </AclRouteHandler>
            ),
            ORG: (
              <AclRouteHandler minRoleLvl={UserRoleEnum.VOLUNTEER}>
                <GerenciarUserOrgs />
              </AclRouteHandler>
            ),
            INSTITUTION: (
              <AclRouteHandler minRoleLvl={UserRoleEnum.VOLUNTEER}>
                <GerenciarUserInst />
              </AclRouteHandler>
            ),
          }}
        />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES_PATH.manageCampaign,
    element: (
      <ProtectedRoute>
        <HandleComponent
          components={{
            GOODERS: (
              <AclRouteHandler minRoleLvl={UserRoleEnum.VOLUNTEER}>
                <GoodersGerenciarCampanhas />
              </AclRouteHandler>
            ),
            ORG: (
              <AclRouteHandler minRoleLvl={UserRoleEnum.VOLUNTEER}>
                <GerenciarCampanhas />
              </AclRouteHandler>
            ),
            INSTITUTION: (
              <AclRouteHandler minRoleLvl={UserRoleEnum.VOLUNTEER}>
                <InstitutionGerenciarCampanhas />
              </AclRouteHandler>
            ),
          }}
        ></HandleComponent>
      </ProtectedRoute>
    ),
  },
  {
    path: '/editar-campanha',
    element: (
      <ProtectedRoute>
        <HandleComponent
          components={{
            ORG: (
              <AclRouteHandler minRoleLvl={UserRoleEnum.VOLUNTEER}>
                <EditarCampanha />
              </AclRouteHandler>
            ),
            INSTITUTION: (
              <AclRouteHandler minRoleLvl={UserRoleEnum.VOLUNTEER}>
                <InstitutionEditarCampanha />
              </AclRouteHandler>
            ),
            GOODERS: (
              <AclRouteHandler minRoleLvl={UserRoleEnum.VOLUNTEER}>
                <GoodersEditarCampanha />
              </AclRouteHandler>
            ),
          }}
        ></HandleComponent>
      </ProtectedRoute>
    ),
  },
  {
    path: '/hash',
    element: (
      <ProtectedRoute>
        <HandleComponent
          components={{
            GOODERS: (
              <AclRouteHandler minRoleLvl={UserRoleEnum.VOLUNTEER}>
                <Hash />
              </AclRouteHandler>
            ),
          }}
        />
      </ProtectedRoute>
    ),
  },
  {
    path: '/generate_hash',
    element: (
      <ProtectedRoute>
        <HandleComponent
          components={{
            GOODERS: (
              <AclRouteHandler minRoleLvl={UserRoleEnum.VOLUNTEER}>
                <GenerateHash />
              </AclRouteHandler>
            ),
          }}
        />
      </ProtectedRoute>
    ),
  },
  {
    path: "pesquisa-web3",
    element: (
      <ProtectedRoute>
        <HandleComponent
          components={{
            GOODERS: (
              <AclRouteHandler minRoleLvl={UserRoleEnum.VOLUNTEER}>
                <Web3Search />
              </AclRouteHandler>
            ),
          }}
        />
      </ProtectedRoute>
    )
  },
  {
    path: '/rescue_hash',
    element: (
      <FormContext>
        <RescueHash />
      </FormContext>
    ),
  },
]);
