import Table from '../../../components/common/Table';
import { useGlobalStore } from '../../../../infra/state/GlobalStore';
import { useEffect, useState } from 'react';
import { useAlert } from '../../../../infra/config/AlertContext/useAlert';
import { Container } from './styles';
import { SearchTableListCampaign } from './components/SearchTableListCampaign';
import { ColumnsTableListCampaign } from './components/ColumnsTableListCampaign';
import { CategoryTableListCampaign } from './components/CategoryTableListCampaign';
import { DateTableListCampaign } from './components/DateTableListCampaign';
import filter from '../../../assets/icons/filter.svg';
import { CardData, CardsTable } from '../../common/CardsTable';
import { useNavigate } from 'react-router-dom';
import {
  CampaignArrayOutput,
  GetListCampaign,
} from '../../../../application/useCases/campaign/GetListCamapign';
import { ModalDelete } from '../../common/ModalDelete';
import { CampaignLinkedWith } from '../../../../application/useCases/campaign/GetCampaignsLinkedWithOrg';
import { ModalContractData } from '../ModalContractData';
import { GetTokenUseCase, Token } from '../../../../application/useCases/web3/getTokenUseCase';
import { odsONU } from '../../shared/gerenciar-campanha/Create/component/CreateCause';

export const TableListCampaign = () => {
  const [campaigns, setCampaigns] = useState<Token[]>([]);
  const [filteredCampaigns, setFilteredCampaigns] = useState<Token[]>([]);
  const [selectedPost, setSelectedPost] = useState<CampaignLinkedWith>();
  const [openModal, setOpenModal] = useState(false);
  const { setAlert } = useAlert();
  const [search, setSearch] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const navigate = useNavigate();
  const clearFilters = () => {
    setSearch('');
    setSelectedCategory('');
    setStartDate('');
    setEndDate('');
  };

  const columns = ColumnsTableListCampaign({ setSelectedPost, setOpenModal });
  const { state } = useGlobalStore();
  useEffect(() => {
    const fetchData = async () => {
      const { error, payload } = await GetTokenUseCase(search);
      if (!error && payload) {
        setCampaigns(payload.tokens);
        setFilteredCampaigns(payload.tokens);
      } else {
        setAlert(error!, 'error');
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    console.log('aqwe');
    setFilteredCampaigns(
      campaigns.filter((c) => {
        console.log(c.name.startsWith(search));
        if (c.name.startsWith(search)) {
          return c;
        }
      })
    );
  }, [search]);

  const rows = (
    campaigns?.map((token, index) => {
      return {
        index: index,
        id: index,
        // Instituição: campaign.institutionName,
        Título: token.name,
        Ods: token.ods,
        // Status: token.isActive ? 'Ativa' : 'Desativada',
        campaignId: token.tokenId,

        // institutionId: campaign.institutionId,
      };
    }) ?? []
  ).sort((a, b) => a['Título'].localeCompare(b['Título']));

  const searchText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCategory(event.target.value);
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>, type: 'start' | 'end') => {
    const value = event.target.value;
    if (type === 'start') {
      setStartDate(value);
    } else {
      setEndDate(value);
    }
  };

  const filterRows = (search: string, selectedCategory: string) => {
    let filteredRows = rows;

    if (search !== '') {
      filteredRows = filteredRows.filter((c) => {
        if (c.Título.startsWith(search)) {
          return c;
        }
      });
    }

    if (selectedCategory) {
      console.log(selectedCategory, filteredRows)
      console.log(odsONU, selectedCategory)
      filteredRows = filteredRows.filter((c) => {
        let index = 0;
        odsONU.find(({ value }, i) => {
          if (value === +selectedCategory) {
            index = i;
            return true;
          }
        });
        console.log("index =>>> " + index);
        if (c.Ods.includes(index)) {
          return c;
        }
      });
    }

    return filteredRows;
  };

  const filteredRows = filterRows(search, selectedCategory);

  function IconEditCampaign(campaignId?: string | number, institutionId?: string | number) {
    navigate(`/editar-campanha?c=${campaignId}&i=${institutionId}`);
  }

  function IconDeleteCampaign(campaignId?: number, institutionId?: number) {
    if (campaignId !== undefined && institutionId !== undefined) {
      setSelectedPost({
        campaignId,
        institutionId,
        summary: '',
        title: '',
        createdAt: '',
        institutionName: '',
        city: '',
        state: '',
        isActive: true,
      });
      setOpenModal(true);
    }
  }

  const cardData: CardData[] = filteredRows.map((campaign) => {
    return {
      id: campaign.campaignId,
      campaignId: campaign.campaignId,
      // institutionId: campaign.institutionId,

      title: [
        'Título',
        //'Instituição',
        'Local',
        //'Status',
      ],
      value: [
        campaign.Título,
        //campaign.,
        campaign.campaignId,
        //campaign.Status ? 'Ativa' : 'Desativada',
      ],
    };
  });

  return (
    <>
      <Container.main>
        <Container.title>
          {' '}
          Filtro
          <Container.search style={{ border: 'none' }}>
            <Container.filterButton onClick={clearFilters}>
              <img src={filter} alt="filter" />
              Limpar
            </Container.filterButton>
          </Container.search>
        </Container.title>
        <Container.filter>
          <SearchTableListCampaign search={search} searchText={searchText} />
          <CategoryTableListCampaign
            selectedCategory={selectedCategory}
            handleCategoryChange={handleCategoryChange}
          />
        </Container.filter>
        <Container.table>
          <Table rows={filteredRows} columns={columns} menuIconDisplay="flex" numberRows={10} />
        </Container.table>
        <Container.mobile>
          <CardsTable
            cardData={cardData}
            IconDeleteCampaign={IconDeleteCampaign}
            IconEditCampaign={IconEditCampaign}
          />
        </Container.mobile>
        {openModal && selectedPost && (
          <ModalContractData
            onClose={() => setOpenModal(false)}
            open={openModal}
            campaignId={selectedPost.campaignId}
            institutionId={selectedPost.institutionId}
            posts={filteredCampaigns}
            setPosts={setFilteredCampaigns}
          />
        )}
      </Container.main>
    </>
  );
};