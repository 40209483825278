import { Container } from './styles';
import { useGlobalStore } from '../../../../../../infra/state/GlobalStore';
import locality from '../../../../../assets/icons/locality.svg';
import setting from '../../../../../assets/icons/setting.svg';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import BRONZE from '../../../../../assets/badge/bronze.svg';
import SILVER from '../../../../../assets/badge/silver.svg';
import GOLD from '../../../../../assets/badge/gold.svg';
import { useWeb3Store } from '../../../../../../infra/state/Web3Store';
import { MdOutlineAccountBalanceWallet, MdAccountBalanceWallet } from 'react-icons/md';

const Badges = {
  BRONZE,
  SILVER,
  GOLD,
};

export const HeaderMyAccount: React.FC = () => {
  const [badge, setBadge] = useState<string>(Badges.BRONZE);
  const store = useGlobalStore();
  const {
    state: { connectedWallet },
  } = useWeb3Store();
  const userBalance = store.state.user?.wallet.balance;

  useEffect(() => {
    if (userBalance != undefined) {
      if (userBalance >= +process.env.REACT_APP_GOLD_BADGE!) return setBadge(Badges.GOLD);
      if (userBalance >= +process.env.REACT_APP_SILVER_BADGE!) return setBadge(Badges.SILVER);
      setBadge(Badges.BRONZE);
    }
  }, [userBalance]);

  const users = useGlobalStore();
  const { user } = users.state;
  const name = user?.name;
  const nameArray = name?.split(' ');
  let firstName = '';
  let lastName = '';

  if (nameArray && nameArray.length === 1) {
    firstName = nameArray[0];
  } else if (nameArray && nameArray.length > 1) {
    firstName = nameArray[0];
    lastName = nameArray[nameArray.length - 1];
  }

  return (
    <Container.head>
      <Container.text>
        <p>Seja bem-vindo!</p>
        <h2 data-test="h2-firstName-lastName">{`${firstName} ${lastName}`}</h2>
      </Container.text>
      <Container.text>
        <Container.headerRight>
          <img style={{ width: '75px' }} src={`/avatar/${user?.avatar}.svg`} alt="avatar" />
          <Container.textUser>
            <Container.textUser>
              <h4 data-test="h4-username">{user?.username}</h4>
              {<img style={{ alignSelf: 'center', width: '30px' }} src={badge} />}
            </Container.textUser>
            <div>
              <img src={locality} alt="localização" />
              <p data-test="p-city">{user?.address.city},</p>
              <p data-test="p-state">{user?.address.state}</p>
            </div>
            <div>
              {connectedWallet ? (
                <p style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                  <MdOutlineAccountBalanceWallet size={18} />
                  {connectedWallet.substring(0, 10)}...{connectedWallet.slice(-4)}
                </p>
              ) : (
                <MdOutlineAccountBalanceWallet size={18} />
              )}
            </div>
          </Container.textUser>
          <Link to="/profile" className="linkSettings">
            <img src={setting} alt="setting" />
          </Link>
        </Container.headerRight>
      </Container.text>
    </Container.head>
  );
};
