import { odsONU } from '../../../../shared/gerenciar-campanha/Create/component/CreateCause';
import { Container, Input } from '../../styles'

interface SearchListCampaignProps {
	selectedCategory: string;
	handleCategoryChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;

}


export const CategoryTableListCampaign: React.FC<SearchListCampaignProps> = ({
	selectedCategory,
	handleCategoryChange,
}) => {
	return (
		<>
			<Container.search>
				<Input.label htmlFor="category-select"></Input.label>
				<Container.select
					id="category-select"
					value={selectedCategory}
					onChange={handleCategoryChange}
				>
					{odsONU.map((ods) => {
						return (
							<option value={ods.value}>{ods.label}</option>
						)
					})}
				</Container.select>
			</Container.search>
		</>
	)
}