import { create, useStore } from 'zustand';
import { User, Acls, selectACLs } from '../../domain/interfaces/User';
import { CampaignArrayOutput } from '../../application/useCases/campaign/GetListCamapign';
import { CampaignLinkedWith } from '../../application/useCases/campaign/GetCampaignsLinkedWithOrg';
import { DashboardPayload } from '../../application/useCases/dahsboard/OrgDashboardUseCase';
import { ListCoupon } from '../../application/useCases/rewards/GetListCouponUseCase';

interface State {
  user: null | User;
  acl: Acls;
  campaigns: CampaignArrayOutput[];
  campaignsList: CampaignLinkedWith[];
  dashboard: DashboardPayload | null;
  rewards: ListCoupon[];
}

interface Actions {
  setUser: (user: User) => void;
  updateACL: (acl: Acls) => void;
  selectACL: (selectedACL: selectACLs) => void;
  setCampaigns: (campaigns: CampaignArrayOutput[]) => void;
  setCampaignsList: (campaigns: CampaignLinkedWith[]) => void;
  setDashboard: (dashboard: DashboardPayload) => void;
  setRewards: (rewards: ListCoupon[]) => void;
}

interface UserState {
  state: State;
  actions: Actions;
}

export const GlobalStore = create<UserState>((set) => ({
  state: {
    user: null,
    acl: {
      selectedACL: null,
      institutions: [],
      orgs: [],
      goodersMember: null,
    },
    campaigns: [],
    campaignsList: [],
    dashboard: null,
    rewards: [],
  },
  actions: {
    setUser: (user: User) => set((state) => ({ state: { ...state.state, user } })),
    updateACL: (updatedACL: Acls) =>
      set((state) => ({
        state: {
          ...state.state,
          acl: {
            ...state.state.acl,
            ...updatedACL,
          },
        },
      })),
    selectACL: (selectedACL) => {
      set((state) => ({
        state: {
          ...state.state,
          acl: {
            ...state.state.acl,
            selectedACL,
          },
        },
      }));
    },
    setCampaigns: (campaigns: CampaignArrayOutput[]) =>
      set((state) => ({ state: { ...state.state, campaigns } })),
    setCampaignsList: (campaignsList: CampaignLinkedWith[]) =>
      set((state) => ({ state: { ...state.state, campaignsList } })),
    setDashboard: (dashboard: DashboardPayload) =>
      set((state) => ({ state: { ...state.state, dashboard } })),
    setRewards: (rewards: ListCoupon[]) => set((state) => ({ state: { ...state.state, rewards } })),
  },
}));

export function useGlobalStore() {
  return useStore(GlobalStore);
}
