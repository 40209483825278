import { GridColDef } from '@mui/x-data-grid';
import Table from '../../../../../../../components/common/Table';
import { useEffect, useState } from 'react';
import {
  TransactionsMoney,
  UserTransactionsMoney,
} from '../../../../../../../../application/useCases/donation/UserTransactionsMoney';
import { useAlert } from '../../../../../../../../infra/config/AlertContext/useAlert';
import logo_coin from '../../../../../../../assets/logo/logo_coin.svg';
import { GetReceiptUseCase } from '../../../../../../../../application/useCases/donation/GetReceiptUseCase';
import { downloadPDF } from '../../../../../../../../application/utils';
import { Container } from '../../styles'
import { CardData, CardsTable } from '../../../../../../../components/common/CardsTable';

export const TableTransactionsDonationPay = () => {
  const { setAlert } = useAlert();
  const [transaction, setTransaction] = useState<TransactionsMoney[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const { error, payload } = await UserTransactionsMoney();
      if (!error && payload) {
        setTransaction(payload.data);
      } else {
        setAlert(error!, 'error');
      }
    };
    fetchData();
  }, []);

  async function downloadReportOnClick(orderId: string) {
    // Não conseguimos verificar a mensagem de erro porque o axios espera um array buffer, o único caso de erro é um 404 que é caso uma ordem não seja encontrada
    const { error, payload, statusCode } = await GetReceiptUseCase(orderId);

    if (statusCode === 404) {
      return setAlert('Ordem de pagamento não encontrada', 'error');
    } else if (error) {
      return setAlert(error, 'error');
    }

    downloadPDF(payload, 'recibo.pdf');
  }

  const formatWalletBalanceValue = (balance: number | undefined) => {
    if (balance) {
      return (balance / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
      });
    } else {
      return '';
    }
  };

  const columns: GridColDef[] = [
    { field: 'Data:', headerName: 'Data:', minWidth: 120, flex: 1, headerClassName: 'table-header', sortable: false, filterable: false, },
    {
      field: 'Valor:', headerName: 'Valor:', minWidth: 120, flex: 1, headerClassName: 'table-header',
    },
    { field: 'Campanha:', headerName: 'Campanha:', minWidth: 300.3, flex: 1, headerClassName: 'table-header', },
    {
      field: 'Status:', headerName: 'Status:', minWidth: 120.3, flex: 1, headerClassName: 'table-header',
      renderCell: (params) => (
        <div
          style={{
            color:
              params.row['Status:'] === 'SUCESSO'
                ? '#01742D'
                : params.row['Status:'] === 'PENDENTE'
                  ? '#ecd500'
                  : params.row['Status:'] === 'CANCELADO'
                    ? '#C31526'
                    : 'inherit',
          }}
        >
          {params.row['Status:']}
        </div>
      ),
    },
    {
      field: 'Gooders Coins', headerName: 'Gooders Coins', minWidth: 120, flex: 1, headerAlign: 'right', align: 'right', headerClassName: 'table-header',
      renderCell: (params) => (
        <div
          style={{
            fontSize: '13px',
            color: 'rgb(1, 116, 45)',
            fontWeight: 600,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img src={logo_coin} alt="logo_coin" style={{ width: '20px', paddingRight: '6px' }} />
          {params.row['Gooders Coins']}
        </div>
      ),
    },
  ];

  const sortedTransactions = [...transaction].sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );

  const rows = sortedTransactions.map((item, index) => ({
    index: index,
    id: item.id,
    'Data:': new Date(item.createdAt).toLocaleDateString('pt-BR'),
    'Valor:': formatWalletBalanceValue(item.amount),
    'Campanha:': item.campaign.title,
    'Recibo:': 'item.status',
    'Status:': item.status,
    'Gooders Coins': String(item.gooders / 100) + ' Coins',
  }));

  const cardData: CardData[] = sortedTransactions.map((item) => ({
    id: item.id,
    title: [
      'Data',
      'Valor',
      'Campanha',
      'Status',
      'Gooders Coins'
    ],
    value: [
      new Date(item.createdAt).toLocaleDateString('pt-BR'),
      formatWalletBalanceValue(item.amount),
      item.campaign.title,
      item.status,
      String(item.gooders / 100)
    ]
  }));


  return (
    <>
      <Container.desktop>
        <Table
          data-test="table-transactions"
          rows={rows}
          columns={columns}
          menuIconDisplay="flex"
          numberRows={5}
        />
      </Container.desktop>
      <Container.mobile>
        <CardsTable cardData={cardData} IconReport={downloadReportOnClick} />
      </Container.mobile>
    </>
  );
};
