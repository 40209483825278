import Table from '../../../../components/common/Table';
import { useGlobalStore } from '../../../../../infra/state/GlobalStore';
import { useEffect, useState } from 'react';
import { useAlert } from '../../../../../infra/config/AlertContext/useAlert';
import { Container } from './styles';
import { SearchTableListCampaign } from './components/SearchTableListCampaign';
import { ColumnsTableListCampaign } from './components/ColumnsTableListCampaign';
import { CategoryTableListCampaign } from './components/CategoryTableListCampaign';
import { DateTableListCampaign } from './components/DateTableListCampaign';
import filter from '../../../../assets/icons/filter.svg';
import { ModalDelete } from '../../../common/ModalDelete';
import { CampaignLinkedWith, GetCampaignsLinkedWith } from '../../../../../application/useCases/campaign/GetCampaignsLinkedWithOrg';
import { DeleteCampaignUseCase } from '../../../../../application/useCases/campaign/OrgDeleteCampaign';
import { CardData, CardsTable } from '../../../common/CardsTable';
import { Link, useNavigate } from 'react-router-dom';


interface Props {
  useCase: GetCampaignsLinkedWith;
  useCaseDelete: DeleteCampaignUseCase;
}

export const TableListCampaign = ({ useCase, useCaseDelete }: Props) => {
  const [campaigns, setCampaigns] = useState<CampaignLinkedWith[]>([]);
  const [selectedPost, setSelectedPost] = useState<CampaignLinkedWith>();
  const [openModal, setOpenModal] = useState(false);
  const { setAlert } = useAlert();
  const [search, setSearch] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const navigate = useNavigate();
  const clearFilters = () => {
    setSearch('');
    setSelectedCategory('');
    setStartDate('');
    setEndDate('');
  };

  const columns = ColumnsTableListCampaign({ setSelectedPost, setOpenModal });
  const { state } = useGlobalStore();

  useEffect(() => {
    const fetchData = async () => {
      if (state.acl.selectedACL) {
        const orgId = state.acl.selectedACL.id;
        const institutionId = state.acl.selectedACL.id;
        const campaignId = state.acl.selectedACL.id
        const { error, payload } = await useCase.execute({ orgId, institutionId, campaignId });
        if (!error && payload) {
          setCampaigns(payload.data)
        } else {
          setAlert(error!, 'error');
        }
      }
    };
    fetchData();
  }, []);

  const rows =
    (campaigns?.map((campaign, index) => {
      return {
        index: index,
        id: index,
        Instituição: campaign.institutionName,
        Título: campaign.title,
        Descrição: campaign.summary,
        Local: campaign.city + " - " + campaign.state,
        Data: new Date(campaign.createdAt),
        Status: campaign.isActive ? 'Ativa' : 'Desativada',
        campaignId: campaign.campaignId,
        institutionId: campaign.institutionId,
      };
    }) ?? [])
      .sort((a, b) => a['Título'].localeCompare(b['Título']));

  const searchText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCategory(event.target.value);
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>, type: 'start' | 'end') => {
    const value = event.target.value;
    if (type === 'start') {
      setStartDate(value);
    } else {
      setEndDate(value);
    }
  };

  const filterRows = (
    search: string,
    selectedCategory: string,
    startDate: string,
    endDate: string
  ) => {
    let filteredRows = rows;

    if (search !== '') {
      const searchTerm = search.toLowerCase();
      filteredRows = filteredRows.filter((row) => {
        const title = row['Título'].toLowerCase();
        const description = row['Descrição'].toLowerCase();
        return title.includes(searchTerm) || description.includes(searchTerm);
      });
    }

    if (startDate !== '' && endDate !== '') {
      filteredRows = filteredRows.filter((row) => {
        const rowDate = new Date(row['Data']);
        const filterStartDate = new Date(startDate);
        const filterEndDate = new Date(endDate);
        return rowDate >= filterStartDate && rowDate <= filterEndDate;
      });
    }
    return filteredRows;
  };

  const filteredRows = filterRows(search, selectedCategory, startDate, endDate);

  function IconEditCampaign(campaignId?: string | number, institutionId?: string | number) {
    navigate(`/editar-campanha?c=${campaignId}&i=${institutionId}`)
  }

  function IconDeleteCampaign(campaignId?: number, institutionId?: number) {
    if (campaignId !== undefined && institutionId !== undefined) {
      setSelectedPost({
        campaignId,
        institutionId,
        summary: '',
        title: '',
        createdAt: '',
        institutionName: '',
        city: '',
        state: '',
        isActive: true
      });
      setOpenModal(true);
    }
  }

  const cardData: CardData[] = filteredRows.map((campaign) => {

    return {
      id: campaign.campaignId,
      campaignId: campaign.campaignId,
      institutionId: campaign.institutionId,

      title: [
        'Título',
        'Instituição',
        'Local',
        'Data',
        'Status',
      ],
      value: [
        campaign.Título,
        campaign.Instituição,
        campaign.Local,
        new Date(campaign.Data).toLocaleDateString('pt-BR'),
        campaign.Status ? 'Ativa' : 'Desativada',
      ],
    };
  });


  return (
    <>
      <Container.main>
        <Container.title>
          {' '}
          Filtro
          <Container.search style={{ border: 'none' }}>
            <Container.filterButton onClick={clearFilters}>
              <img src={filter} alt="filter" />
              Limpar
            </Container.filterButton>
          </Container.search>
        </Container.title>
        <Container.filter>
          <SearchTableListCampaign search={search} searchText={searchText} />
          <CategoryTableListCampaign
            selectedCategory={selectedCategory}
            handleCategoryChange={handleCategoryChange}
          />
          <DateTableListCampaign
            startDate={startDate}
            endDate={endDate}
            handleDateChange={handleDateChange}
          />
        </Container.filter>
        <Container.table>
          <Table rows={filteredRows} columns={columns} menuIconDisplay="flex" numberRows={10} />
        </Container.table>
        <Container.mobile>
          <CardsTable cardData={cardData} IconDeleteCampaign={IconDeleteCampaign} IconEditCampaign={IconEditCampaign} />
        </Container.mobile>
        {openModal && selectedPost && (
          <ModalDelete
            onClose={() => setOpenModal(false)}
            open={openModal}
            campaignId={selectedPost.campaignId}
            institutionId={selectedPost.institutionId}
            posts={campaigns}
            setPosts={setCampaigns}
            useCase={useCaseDelete}
          />
        )}
      </Container.main>
    </>
  );
};
